<template>
    <div>
        <div style="background: rgb(246, 246, 246);">
            <div class="hetongclass">
                <div style="display: flex;margin: 0 auto;width: 345px;">
                    <van-icon class="fanhui" name="arrow-left" color="#202020" size="24" @click="fanhui" />
                    <div class="hetongguanl">文件详情</div>
                </div>
            </div>
            <div class="wenjian">
                <div class="wenjianjuzhong">
                    <p class="shanghai">{{shuzuclass.name}}</p>
                    <p class="htbianhao">合同编号：{{shuzuclass.number}}</p>
                    <div class="zhuangtai">
                        状态：
                        <span style="color: #1872ED;" v-if="shuzuclass.status == 1">待我签</span>
                        <span style="color: #2CC797;" v-if="shuzuclass.status == 2">已完成</span>
                        <span style="color: #FF9A40;" v-if="shuzuclass.status == 3">已过期</span>
                        <span style="color: #FF9A40;" v-if="shuzuclass.status == 4">已撤回</span>
                        <span style="color: #FF5227;" v-if="shuzuclass.status == 5">已拒签</span>
                    </div>
                    <p class="jiezhisj">签署截止日期：{{shuzuclass.end_time}}</p>
                    <div class="gekai"></div>
                    <p class="wenjianlist">文件：</p>
                    <div class="wenjianhz" @click="contractInfo">
                        <img class="file-icon" src="../../assets/img/pdftu.png" />
                        <p style="margin-left: 4.5px;">{{shuzuclass.title}}</p>
                    </div>
                </div>
            </div>
            <div class="qianshu">
                <div class="wenjianjuzhong">
                    <p class="qianshufang">签署方</p>
                    <div class="benwenjian">本文件由<span style="text-decoration:underline;">{{shuzuclass.company}} </span>于{{shuzuclass.create_time}} 发起</div>
                </div>
                <div class="faqiren">
                    <div style="display: flex;margin-top: 15px;margin-left: 10px;">
                        <div class="faqilist">发起人</div>
                        <p class="tengface">{{shuzuclass.real_name}}</p>
                        <img style="margin-left: 2px;height: 12PX;margin-top: 4px;" src="../../assets/images/weirenzhengyy.png" alt="">
                    </div>
                    <div class="wanchengqs">于{{shuzuclass.create_time}}
                        <span style="color: #1872ED;margin-left: 2px;" v-if="shuzuclass.status != 4">完成签署</span>
                        <span style="color: #FF9A40;margin-left: 2px;" v-if="shuzuclass.status == 4">操作合同撤回</span>
                    </div>
                    <div v-if="shuzuclass.status == 4" class="wanchengqs">撤回原因：{{shuzuclass.reason}}</div>
                </div>
                <div class="faqiren">
                    <div style="display: flex;margin-top: 15px;margin-left: 10px;">
                        <div class="faqilist" style="background: #2cc797;">接收人</div>
                        <p class="tengface">{{shuzuclass.nick_name}}</p>
                        <span style="margin-top: 2px;">（{{shuzuclass.phone}}）</span>
                    </div>
                    <div v-if="shuzuclass.modify_time != null" class="wanchengqs">于{{shuzuclass.modify_time}}
                        <span v-if="shuzuclass.modify_time != null && shuzuclass.status == 3">接收</span>
                        <span style="color: #1872ED;margin-left: 2px;" v-if="shuzuclass.status == 2">完成签署</span>
                        <span style="color: #FF5227;margin-left: 2px;" v-if="shuzuclass.status == 5">拒签</span>
                    </div>
                    <div v-if="shuzuclass.status == 5" class="wanchengqs">拒签原因：{{shuzuclass.reason}}</div>
                </div>
            </div>
            <div class="zuixiafang">
                <div class="juzhongjq" v-if="shuzuclass.status == 1">
                    <p class="juqians" @click="showPopup">拒签</p>
                    <div class="chakanqs" @click="quanshuqu()">查看并签署</div>
                </div>
                <div class="juzhongjq" v-if="shuzuclass.status != 1">
                    <div class="chakanqsyi" @click="quanshuqu()">查看详情</div>
                </div>
            </div>
        </div>
        <van-popup class="chehui" v-model="show">
            <div class="juqiants">
                <div style="width: 295px;margin: 0 auto;">
                    <p class="juqianzi">执行【拒签】操作，表示您拒绝签署本文件，本次签署将终止。请确认是否执行本操作？</p>
                </div>
            </div>
            <div class="yuanying">
                请填写拒签原因<span style="color: #999999;">（必填）</span>
            </div>
            <div style="margin-top: 15px;">
                <van-field
                class="shuruk"
                v-model="message"
                rows="6"
                autosize
                type="textarea"
                placeholder="请把内容控制在100字以内"
                />
            </div>
            <div class="anniukuang">
                <div class="quxiaoanniu" @click="show = false">取消</div>
                <div class="quedinganniu" @click="contractResist">确定</div>
            </div>
        </van-popup>
        <van-overlay :show="showlist" :duration='0'>
            <div class="wrapper" @click.stop>
                <div class="block">
                    <router-link :to="{path:'Personalauthentication',query:{setid:123123}}">
                        <p class="fayuyq">根据法律要求，合法合规的合同签署需申请数字证书，请您先进行实名认证。</p>
                        <div class="renzhengan">好的，去认证</div>
                    </router-link>
                </div>
                <!-- <img class="quxiao" @click="show = false" src="../../assets/images/quxiaoan.png" alt=""> -->
            </div>
        </van-overlay>
        <!-- 加载laoding -->
        <van-popup class="loading-popup" v-model="isDownloading">
             <van-loading color="#0094ff" vertical>
                {{ contractPictureList.length === 0 ? '正在加载合同文件' : '正在生成下载文件' }}
            </van-loading>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';
import jsPdf from 'jspdf'
import { convertResToBlob } from '@/utils'

export default {
    name: "allContracts",
    data() {
        return {
            showlist: false,
            show: false,
            message: '',
            shuzuclass: [],
            contractPictureList: [],
            isDownloading: false
        }
    },
    created () {
        // this.contractInfo()
        this.contractStatusInfo()
    },
    mounted() {
        setTimeout(() => {
            document.title = ''
        }, 1000)
    },
    methods: {
        // tiaoxiazai() {
        //     this.$router.push({
        //         path: "/ViewSigning",
        //         query: {
        //             id: this.shuzuclass.id,
        //             sign_id: this.shuzuclass.sign_id,
        //             // PDFDownload: 1
        //         }
        //     })
        // },
        async getUserInfo(phone){
            if (!localStorage.getItem('token')) {
                this.$router.push({ name: 'zhanghaomimadl', query: { redirectUrl:location.href } })
            }
            const { data: { code, data } } = await this.$api.post('/user/index/getStudentPhone')
            if(code !== 200) {
                Toast.fail('获取当前用户手机号失败')
                return
            }
            // 通过手机号判断该合同是否属于当前登录的用户
            if(data !== phone) {
                Toast.fail('账号不符')
                sessionStorage.removeItem("token")
                this.$router.push({ name: 'Accountmismatch', query: { redirectUrl:location.href } })
                return
            }
            // 查询用户实名
            const res = await this.$api.post('/user/index/getUserInfo')
            if(res.data.code === 200){
                if(res.data.data.authentication == 2) {
                    this.showlist = false
                } else {
                    this.showlist = true
                }
            }
        },
        contractResist() {
            if(this.message == '') {
                Toast.fail('请输入拒签原因');
                return
            }
            const data = {
                id: this.shuzuclass.id,
                sign_id:this.shuzuclass.sign_id,
                reason: this.message
            }
            this.$api.post('/user/app/contractResist', data)
                .then(res=>{
                    if(res.data.code == 200) {
                        this.$toast.success('拒签成功')
                        this.show = false
                        this.contractStatusInfo()
                    }
                })
        },
        contractStatusInfo() {
            const data = {
                // token: 'wx_35a7610c10d7b1ccaca6f560745f88e6_2_1491_22506957',
                id: this.$route.query.id,
                sign_id:this.$route.query.sign_id,
            }
            this.$api.post('/user/app/contractStatusInfo', data)
                .then(res=>{
                    if(res.data.code == 200) {
                        this.shuzuclass = res.data.data
                        this.getUserInfo(res.data.data.phone)
                    } else if(res.data.code == 209) {
                        Toast.fail('账号不符');
                        sessionStorage.removeItem("token")
                        this.$router.push({ name: 'Accountmismatch', query: { redirectUrl:location.href } })
                    }
                })
        },
        contractInfo(){
            this.downloadContractPdf()
            // const data = {
            //     id: this.$route.query.id,
            //     sign_id:this.$route.query.sign_id
            // }
            // this.$api.post('https://online.tenghuiketang.com/onlineapi/user/app/contractInfo', data)
            //     .then(res=>{
            //         if(res.data.code == 200) {
            //             console.log('333333-------', res.data.data)
            //             // this.suoyuhtlsit = res.data.data
            //             this.isDownloading = true
            //             this.hetongname = res.data.data.sign.name
            //             this.contractPdfTransforImgs(res.data.data.sign.address || res.data.data.sign.contract)
            //         }
            //     })
        },
        contractPdfTransforImgs(url) {
            // this.$api.post('/user/app/changePic', {
            //     file: url
            // }).then(({ data: { code, data: resData } }) => {
            //     if(code === 200) {
            //         this.contractPictureList = resData
            //         this.createImgElmAndDownload()
            //     }
            //     // this.isDownloading = false
            // })
        },
        createImgElmAndDownload() {
            // try {
            //     const PDF = new jsPdf('p', 'px', 'a4')
            //     // const imgWidth = 595.28
            //     // const imgHeight = 841.89
            //     var imgWidth = PDF.internal.pageSize.getWidth()
            //     var imgHeight = PDF.internal.pageSize.getHeight()
            //     // let position = 0
            //     this.contractPictureList.forEach((route, index) => {
            //         console.log(index);
            //         const imgElm = new Image()
            //         imgElm.src = `${route}?${new Date()}`
            //         imgElm.crossOrigin = 'anonymous'
            //         // imgElm.style.width = '100%'
            //         // imgElm.style.height = '841px'
            //         PDF.addImage(imgElm, 'JPEG', 0, 0, imgWidth, imgHeight)
            //         // // position -= 841.89
            //         if(this.contractPictureList.length > (index + 1)) {
            //             console.log('123', index);
            //             PDF.addPage()
            //         }
            //     })
            //     PDF.save(`${this.hetongname}.pdf`)
            //     this.isDownloading = false
            // } catch(error) {
            //     this.isDownloading = false
            // }
        },
        quanshuqu() {
            this.$router.push({
                path: "/ViewSigning",
                query: {
                    id: this.shuzuclass.id,
                    sign_id: this.shuzuclass.sign_id
                }
            })
        },
        showPopup() {
            this.show = true;
        },
        fanhui() {
            this.$router.push({
                path: "/contractmanagement",
            })
        },
        /**
         * 描述：下载文件流
        **/
        async downloadContractPdf() {
            // if(this.shuzuclass.status === 2) {
            const { data: { data: privateContractUrl } } = await this.$api.post('/user/app/cosSign', { 
                address: decodeURIComponent(this.shuzuclass.address || this.shuzuclass.contract)
            })
            window.location.href = privateContractUrl
            // } else {
            //     this.$api({
            //         method: "get",
            //         url: this.shuzuclass.contract,
            //         headers: {
            //             "content-type": "application/json; charset=utf-8",
            //         },
            //         responseType: "blob",
            //     }).then(res => {
            //         convertResToBlob(res.data, `${this.shuzuclass.name}.pdf`)
            //     })
            // }
            
        }
    }
}
</script>

<style scoped>
.loading-popup {
    background: transparent;
}
.fayuyq {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #202020;
    margin: 0 21px;
    margin-top: 122.13px;
    margin-bottom: 40px;
}
.renzhengan {
    width: 176px;
    height: 44px;
    font-size: 15px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    text-align: center;
    line-height: 44px;
    color: #ffffff;
    background: #1872ed;
    border-radius: 24px;
    margin: 0 auto;
    box-shadow: 0px 6px 14px 0px rgba(255,255,255,0.30) inset, 0px -6px 14px 0px rgba(255,255,255,0.30) inset, 0px 5px 8px 0px rgba(2,90,211,0.16); 
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 318px;
    height: 271px;
    /* background-color: #fff; */
    background-image: url(../../assets/images/renzhengtk.png);
    background-size: 100% 100%;
  }
.chakanqsyi {
    width: 343px;
    height: 45px;
    background: #1872ed;
    border-radius: 4px;
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 45px;
    margin-top: 10px;
}
.quedinganniu {
    width: 64px;
    height: 36px;
    background: linear-gradient(120deg,#73aaff 30%, #0053ff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    margin-left: 20px;
}
.quxiaoanniu {
    width: 64px;
    height: 36px;
    background: #dfe3e8;
    border-radius: 4px;
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    line-height: 36px;
    text-align: center;
}
.anniukuang {
    display: flex;
    width: 148px;
    margin: 0 auto;
    margin-top: 16px;
}
.shuruk {
    width: 295px;
    height: 161px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin: 0 auto;
}
.yuanying {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-top: 16px;
    margin-left: 20px;
}
.juqianzi {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #FFFFFF;
    /* margin-left: 20px;
    margin-right: 15px; */
    margin-top: 14px;
}
.juqiants {
    width: 335px;
    height: 56px;
    background: #1872ED;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}
.chehui {
    width: 335px;
    height: 330px;
    background: #ffffff;
    border-radius: 8px;
}
.juqians {
    width: 42px;
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    color: #666666;
    margin-top: 20px;
}
.chakanqs {
    width: 100%;
    height: 45px;
    background: #1872ed;
    border-radius: 4px;
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;
    text-align: center;
    margin-left: 30px;
    margin-top: 10px;
}
.juzhongjq {
    display: flex;
    width: 345px;
    margin: 0 auto;
}
.zuixiafang {
    width: 100%;
    height: 65px;
    background: #ffffff;
    /* position: absolute;
    bottom: 0; */
}
.wanchengqs {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
    margin-left: 10px;
    padding-bottom: 10px;
}
.tengface {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-left: 4px;
}
.faqilist {
    width: 40px;
    height: 18px;
    background: #1872ed;
    border-radius: 2px;
    line-height: 18px;
    text-align: center;
    font-size: 10px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #ffffff;
}
.faqiren {
    width: 345px;
    min-height: 70px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    margin: 0 auto;
    /* margin-left: 16px; */
    margin-top: 10px;
}
.benwenjian {
    font-size: 11px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    /* margin-left: 16px; */
    margin-top: 10px;
}
.qianshufang {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 600;
    color: #202020;
    /* margin-left: 16px; */
    margin-top: 19px;
}
.qianshu {
    width: 100%;
    min-height: 300px;
    background: #ffffff;
    overflow: hidden;
    margin-top: 8px;
}
.file-icon {
    width: 25px;
    height: 25px;
}
.wenjianhz {
    display: flex;
    margin-top: 12px;
    /* margin-left: 18.5px; */
    line-height: 28px;
}
.wenjianlist {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    margin-top: 14px;
    /* margin-left: 16px; */
}
.gekai {
    width: 343px;
    height: 0px;
    border: 1px solid #f2f2f2;
    margin: 0 auto;
    margin-top: 14px;
}
.jiezhisj {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    margin-top: 8px;
    /* margin-left: 16px; */
}
.zhuangtai {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    margin-top: 22px;
    /* margin-left: 16px; */
}
.htbianhao {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
    /* margin-left: 16px; */
}
.shanghai {
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 600;
    color: #202020;
    margin-top: 21px;
    /* margin-left: 16px; */
}
.wenjianjuzhong {
    width: 345px;
    margin: 0 auto;
}
.wenjian {
    width: 100%;
    height: 232px;
    background: #ffffff;
    margin-top: 10px;
    overflow: hidden;
}
.hetongguanl {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC-700;
    font-weight: 700;
    color: #202020;
    margin-left: 110px;
    line-height: 44px;
}
.fanhui {
    /* margin-left: 17px; */
    line-height: 44px;
}
.hetongclass {
    width: 100%;
    height: 44px;
    background: #fff;
}
* {
    margin: 0;
    padding: 0;
}
</style>